import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import PageSection from '../components/shared/sections/page-section';
import StepSection from '../components/shared/sections/step-section';
import CallToActionSection from '../components/shared/sections/call-to-action-section';
import HomeHeaderSection from '../components/sections/home/header-section';
import Seo from '../components/config/Seo';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import StartupsSection from '../components/shared/sections/startups-section/';
import NewsSection from '../components/shared/sections/news-section';
import useGetHomePageTranslation from '../hooks/useGetHomePageTranslation';
import AOS from 'aos';
import useGetUserAgent from '../hooks/useGetUserAgent';

const Index = ({ data }) => {
  const {
    pageTitle,
    homeHeaderSection,
    pageSection,
    stepSection,
    callToActionSection,
  } = useGetHomePageTranslation();
  const theme = useTheme();
  const smallScreen = useMediaQuery(
    theme.breakpoints.down('xs')
  );
  const mediumScreen = useMediaQuery(
    theme.breakpoints.down('sm')
  );
  const { isInApp } = useGetUserAgent();

  useEffect(() => {
    AOS.init({
      disable: isInApp,
    });
  }, [isInApp]);

  return (
    <>
      <Seo title={pageTitle} />
      <HomeHeaderSection
        title={homeHeaderSection.title}
        description={homeHeaderSection.description}
        gatsbyImg={{
          fluid:
            data.pageSections.edges[0].node.childImageSharp
              .fluid,
          width: 400,
          height: 400,
          pattern: 'alternatedSquares',
        }}
      />
      <PageSection
        title={pageSection.title}
        description={pageSection.description}
        buttonText={pageSection.buttonText}
        gatsbyImg={{
          fluid:
            data.pageSections.edges[1].node.childImageSharp
              .fluid,
          width:
            mediumScreen && !smallScreen
              ? 478
              : smallScreen
              ? 273
              : 570,
          height: smallScreen ? 200 : 322,
          pattern: 'arch',
          objectFit: 'none',
          objectPosition: 'center',
          imgStyle: {
            width:
              mediumScreen && !smallScreen
                ? 478
                : smallScreen
                ? 273
                : 570,
            height:
              mediumScreen && !smallScreen
                ? ''
                : smallScreen
                ? 273
                : 315,
            filter:
              'drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.15))',
          },
        }}
      />
      <StepSection
        title={stepSection.title}
        steps={[...stepSection.steps]}
      />
      <StartupsSection
        data={
          data.allContentfulStartupListHomePage.edges[0]
            .node
        }
      />
      <NewsSection
        data={data.allContentfulNewsEntry.edges}
      />
      <CallToActionSection
        title={callToActionSection.title}
        description={callToActionSection.description}
        buttonTitle={callToActionSection.buttonTitle}
      />
    </>
  );
};

export default Index;

export const query = graphql`
  query HomePage {
    pageSections: allFile(
      filter: {
        relativePath: { regex: "/^page-sections/home/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    allContentfulStartupListHomePage {
      edges {
        node {
          startups {
            ... on ContentfulStartupEntry {
              name
              description {
                description
              }
              image {
                fluid(quality: 85) {
                  ...GatsbyContentfulFluid_noBase64
                }
              }
            }
            ... on ContentfulStartupQuoteEntry {
              quote {
                quote
              }
            }
          }
        }
      }
    }
    allContentfulNewsEntry(
      limit: 5
      sort: { fields: date, order: DESC }
      filter: { category: { ne: "Quote" } }
    ) {
      edges {
        node {
          image {
            fluid(quality: 85) {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          category
          title
          text {
            text
          }
          date(formatString: "dddd, MMM Do - YYYY")
          slug
          cardWidth
          preamble {
            preamble
          }
        }
      }
    }
  }
`;
