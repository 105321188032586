import patterns from '../../../../constants/patterns';

export default ({
  palette: { secondary, text },
  breakpoints: { down },
  spacing,
  fonts,
}) => ({
  HomeHeaderSection: {
    backgroundColor: secondary.main,
    color: text.primary,
    height: '100vh',
    minHeight: 980,
    overflow: 'hidden',
    [down('sm')]: {
      height: 'auto',
    },
    [down('xs')]: {
      height: ({ height }) => height,
      maxHeight: ({ height }) => height,
      minHeight: ({ height }) => height,
    },
  },
  gridContainer: {
    zIndex: 2,
    position: 'relative',
    [down('md')]: {
      paddingTop: spacing(15),
    },
    [down('sm')]: {
      paddingTop: spacing(15),
    },
    [down('xs')]: {
      paddingTop: spacing(0),
    },
  },
  textContainer: {
    position: 'relative',
    height: '100%',
    zIndex: 3,
    [down('md')]: {
      padding: spacing(10, 0),
    },
    [down('xs')]: {
      height: 'auto',
    },
  },
  title: {
    fontSize: fonts.size.xxxxl,
    lineHeight: fonts.size.xxxxl + 'px',
    fontWeight: 700,
    whiteSpace: 'pre-line',
    [down('xs')]: {
      fontSize: fonts.size.xxl,
      lineHeight: fonts.size.xxl + 'px',
      fontWeight: 700,
    },
    [down(325)]: {
      fontSize: fonts.size.l,
    },
  },
  description: {
    whiteSpace: 'pre-line',
    marginTop: spacing(4),
    maxWidth: '90%',
    [down('md')]: {
      maxWidth: 700,
    },
    [down('xs')]: {
      marginTop: spacing(2.5),
      maxWidth: '285px !important',
    },
  },
  imageContainer: {
    position: 'relative',
    marginTop: spacing(4),
    height: 400,
    width: 400,
    filter: 'drop-shadow(0px 4px 50px rgba(0, 0, 0, 0.15))',
    zIndex: 3,
    [down('md')]: {
      margin: spacing(17, 23, 0, 0),
      alignSelf: 'flex-end',
    },
    [down('sm')]: {
      margin: spacing(17, 15, 15, 0),
    },
    [down('xs')]: {
      display: 'none',
    },
  },
  subTitleText: {
    position: 'relative',
    paddingLeft: spacing(2),
    fontSize: fonts.size.m,
    lineHeight: fonts.size.m + 12 + 'px',
    fontWeight: 400,
    [down('xs')]: {
      fontSize: fonts.size.xxs,
      lineHeight: fonts.size.xxs + 6 + 'px',
      fontWeight: 500,
      paddingLeft: spacing(0),
    },
    '&::before': {
      content: '""',
      width: 2,
      height: 'calc(100% - 20px)',
      backgroundColor: 'currentColor',
      position: 'absolute',
      opacity: 0.2,
      marginRight: spacing(2),
      top: 0,
      left: 0,
      overflow: 'hidden',
      margin: 'auto',
      bottom: 0,
      [down('xs')]: {
        display: 'none',
      },
    },
  },
  bottomPattern: {
    background: `url(${patterns['arrow']})`,
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    bottom: 0,
    left: 0,
    opacity: 0.2,
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      height: '100vh',
      width: '100vw',
      background: `linear-gradient(180.04deg, ${secondary.main} 52.61%, rgba(252, 249, 242, 0.69) 99.96%)`,
    },
  },
  circleContainer: {
    position: 'relative',
    zIndex: -1,
    width: '100%',
    '& .parallax-outer': {
      position: 'absolute',
      right: 0,
      height: 266,
      [down(325)]: {
        height: 200,
      },
    },
    '& .parallax-inner': {
      position: 'absolute',
      right: 0,
      height: '100%',
    },
  },
});
