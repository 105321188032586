import React from 'react';
import {
  Grid,
  Typography,
  useMediaQuery,
  Box,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/styles';
import Image from '../../../shared/Image';
import Container from '../../../shared/Container';
import { string, shape, number, oneOf } from 'prop-types';
import Circle from '../../../shared/Circle';
import styles from './index.styles';
import { variants } from '../../../../constants/patterns';
import { Parallax } from 'react-scroll-parallax';
import useGetWindowHeight from '../../../../hooks/useGetWindowHeight';
import useGetUserAgent from '../../../../hooks/useGetUserAgent';

const useStyles = makeStyles(styles);

const HomeHeaderSection = ({
  title,
  description,
  gatsbyImg,
}) => {
  const theme = useTheme();
  const height = useGetWindowHeight();
  const { isMobile } = useGetUserAgent();
  const classes = useStyles({ height });

  const extraSmallScreen = useMediaQuery(
    theme.breakpoints.down(325)
  );
  const smallScreen = useMediaQuery(
    theme.breakpoints.down('xs')
  );
  const mediumScreen = useMediaQuery(
    theme.breakpoints.down('md')
  );
  return (
    <Grid
      container
      className={classes.HomeHeaderSection}
      alignContent="center"
    >
      <Container>
        <Grid
          container
          className={classes.gridContainer}
          direction={mediumScreen ? 'column' : 'row'}
        >
          <Grid
            container
            item
            md={9}
            lg={7}
            direction={mediumScreen ? 'column' : 'row'}
            className={classes.textContainer}
            alignContent={
              mediumScreen ? 'flex-start' : 'center'
            }
          >
            <Box className={classes.circleContainer}>
              <Parallax
                y={
                  mediumScreen && !smallScreen
                    ? [-45, 45]
                    : smallScreen
                    ? [-15, 30]
                    : [-50, 50]
                }
                disabled={isMobile}
              >
                <Circle
                  size={extraSmallScreen ? 200 : 266}
                  useCssToPosition={true}
                />
              </Parallax>
            </Box>
            <Typography
              component="h1"
              className={classes.title}
            >
              {title}
            </Typography>
            <Grid
              container
              className={classes.description}
              direction="row"
            >
              <Typography
                component="p"
                className={classes.subTitleText}
              >
                {description}
              </Typography>
            </Grid>
          </Grid>
          {!smallScreen && (
            <Grid
              item
              container
              md={5}
              className={classes.imageContainer}
            >
              <Image
                {...gatsbyImg}
                right={mediumScreen ? -130 : -160}
                top={
                  height <= 900
                    ? 0
                    : mediumScreen
                    ? -140
                    : -90
                }
                parallax={true}
                parallaxProps={
                  mediumScreen ? [0, 40] : [-50, 50]
                }
              />
            </Grid>
          )}
        </Grid>
        {smallScreen && (
          <Box className={classes.bottomPattern} />
        )}
      </Container>
    </Grid>
  );
};

HomeHeaderSection.propTypes = {
  title: string,
  description: string,
  gatsbyImg: shape({
    fluid: shape({}),
    width: number,
    height: number,
    pattern: oneOf(variants),
  }),
};

export default HomeHeaderSection;
